@import '~reset-css/reset.css';
@import '~src/styles/variables';
@import '~src/styles/colors';

:root {
  color: $color-black;
  font-size: $font-size-desktop;
  font-family: $font-family-1;
  line-height: 1.5em;
  --menu-height: $layout-menu-height-desktop;

  @media screen and (max-width: $version-breakpoint) {
    font-size: $font-size-mobile;
    font-family: $font-family-1;
    line-height: 1.5em;
    --menu-height: $layout-menu-height-mobile;
  }
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

body {
  background-color: $container-surface;
}
