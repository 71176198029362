// Core Component
$layout-page-width: var(--page-width);
$layout-viewport-height: var(--viewport-height);

// Generic
$version-breakpoint: 768px;

// Grid Mobile
$grid-columns-mobile: 4;
$grid-gutter-mobile: 24px;
$container-width-mobile: 400px;
$container-padding-mobile: 8px;

// Grid Desktop
$grid-columns-desktop: 12;
$grid-gutter-desktop: 24px;
$container-width-desktop: 1320px;
$container-padding-desktop: 32px;

// Layout
$layout-footer-height: 200vh;
$layout-menu-height-desktop: 96px;
$layout-menu-height-mobile: 72px;

// Boxshadow
$boxshadow-color: #000000;
$boxshadow-weak: 0.06;
$boxshadow-medium: 0.12;
$boxshadow-strong: 0.16;

// Typography
$font-size-desktop: 20px;
$font-size-mobile: 16px;
$font-family-1: "Montserrat", sans-serif;
$font-family-2: "Montserrat", monospace;
$font-family-3: "Montserrat", sans-serif;
$line-height: 4px;

// Spacing
$spacing: 8px;

// Z-index
$zindex-negative: -1;
$zindex-0: 0;
$zindex-1: 10;
$zindex-2: 20;
$zindex-3: 30;
$zindex-4: 40;
$zindex-5: 50;
$zindex-6: 60;
$zindex-7: 70;
$zindex-8: 80;
$zindex-9: 90; // Opened menus
$zindex-10: 100; // Transition Element
