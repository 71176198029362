// Basic Colors
$color-white: #FFFFFF;
$color-black: #333333;

$color-gray-lighter: #FAFAFA;
$color-gray-light: #F2F2F2;
$color-gray: #EEEEEE;
$color-gray-dark: #8C8C8C;
$color-gray-darker: #5C5C5C;

$color-primary-lighter: #CEEEF5;
$color-primary-light: #71BFD1;
$color-primary: #047C9B;
$color-primary-dark: #005063;
$color-primary-darker: #013D4C;


$color-invalid: #c21e2c;
$color-disabled: #AEAEAE;

// Container Colors
$container-image: linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32));
$container-background: #{$color-gray-lighter};
$container-surface: #{$color-white};
$container-primary: #{$color-primary};
$container-secondary: #{$color-primary-darker};

// Gradient Colors
$linear-gradient-primary: linear-gradient(180deg, #{rgba($color-primary, 0.4)} 0%, #{rgba($color-primary-dark, 0.4)} 100%);
$linear-gradient-secondary: linear-gradient(180deg, #{rgba($color-white, 0.25)} 0%, #{rgba($color-primary, 0.25)} 100%);
$linear-gradient-gray: linear-gradient(180deg, #{rgba($color-gray-lighter, 0.25)} 0%, #{rgba($color-primary-light, 0.25)} 100%);
$linear-gradient-image: #{$container-image};

// Content Colors
$content-image-high: #{$color-white};
$content-image-medium: #{rgba($color-white, 0.75)};
$content-image-disabled: #{$color-gray};
$content-image-detail: #{rgba($color-white, 0.75)};
$content-image-outline: #{$color-white};

$content-surface-high: #{$color-black};
$content-surface-medium: #{$color-gray-darker};
$content-surface-disabled: #{$color-disabled};
$content-surface-detail: #{$color-primary-light};
$content-surface-outline: #{rgba($color-primary-light, 0.25)};

$content-primary-high: #{$color-white};
$content-primary-medium: #{$color-gray-light};
$content-primary-disabled: #{$color-disabled};
$content-primary-detail: #{$color-white};
$content-primary-outline: #{$color-white};

// Accent Colors
$content-accent-high: #{$color-primary-dark};
$content-accent-medium: #{$color-primary};
$content-accent-light: #{$color-primary-light};
$content-accent-lighter: #{$color-primary-lighter};
